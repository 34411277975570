<template>
  <b-card>
    <g-form @submit="save">
      <b-row class="message-settings mb-2">
        <!-- previous Balance Case -->
        <b-col
          class="gb-package"
          md="12"
        >
          <label> {{ $t("smsProvider") }} </label>
          <span>
            <feather-icon
              icon="AlertOctagonIcon"
            />
            {{ $t("providerSelectionHint") }} </span>
          <b-button-group>
            <b-button
              :disabled="helper.isOriginalActive && helper.selectedProviderName !== 'smsGateway' "
              :class="['gb-btn-child', {btnActive: helper.selectedProviderName === 'smsGateway'}]"
              variant="flat-primary"
              @click="smsGatewaySelected()"
            >
              {{ $t("smsGateway") }}
            </b-button>
            <b-button
              :disabled="helper.isOriginalActive && helper.selectedProviderName !== 'smS4Jawaly' "
              :class="['gb-btn-child', {btnActive: helper.selectedProviderName === 'smS4Jawaly'}]"
              variant="flat-primary"
              @click="smS4JawalySelected()"
            >
              {{ $t("forJawaly") }}
            </b-button>
            <b-button
              :disabled="helper.isOriginalActive && helper.selectedProviderName !== 'smsMsegat'"
              :class="['gb-btn-child', {btnActive: helper.selectedProviderName === 'smsMsegat'}]"
              variant="flat-primary"
              @click="smsMesgatSelected()"
            >
              {{ $t("mesgat") }}
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>

      <!-- sender url  -->
      <b-row>
        <b-col
          md="12"
          style="margin: 0 auto;"
        >
          <b-row>
            <!-- company logo -->
            <b-col md="12">
              <a
                :href="providerWebiste"
                target="_blank"
              >
                <img
                  :src="logoPath"
                  class="logo-img"
                >
              </a>
            </b-col>

            <!-- sender name  -->
            <b-col md="12">
              <b-form-group>
                <g-field
                  id="senderName"
                  name="senderName"
                  label-text="senderName"
                  :value.sync="selectedItem.senderName"
                />
              </b-form-group>
            </b-col>

            <!-- sender url  -->
            <b-col md="12">
              <b-form-group>
                <g-field
                  id="senderUrl"
                  name="senderUrl"
                  label-text="sender Url"
                  hidden
                  :value.sync="selectedItem.senderUrl"
                />
              </b-form-group>
            </b-col>

            <!-- template id  -->
            <b-col md="6">
              <b-form-group>
                <g-field
                  id="templateId"
                  name="templateId"
                  label-text="template Id"
                  :value.sync="selectedItem.templateId"
                />
              </b-form-group>
            </b-col>

            <!-- api id -->
            <b-col md="6">
              <b-form-group>
                <g-field
                  id="apiId"
                  name="apiId"
                  label-text="api Id"
                  rules="required"
                  :value.sync="selectedItem.apiId"
                />
              </b-form-group>
            </b-col>

            <!-- sender Id  -->
            <b-col md="6">
              <b-form-group>
                <g-field
                  id="senderId"
                  name="senderId"
                  label-text="sender Id"
                  rules="required"
                  :value.sync="selectedItem.senderId"
                />
              </b-form-group>
            </b-col>

            <!-- password  -->
            <b-col md="6">
              <b-form-group>
                <g-field
                  id="apiPassword"
                  name="apiPassword"
                  label-text="api Password"
                  rules="required"
                  :value.sync="selectedItem.apiPassword"
                />
              </b-form-group>
            </b-col>

            <!-- is Active -->
            <b-col
              md="12"
            >
              <b-form-checkbox
                v-model="selectedItem.isActive"
                name="check-button"
                switch
                inline
              >
                {{ selectedItem.isActive ? $t('active') : $t('unActive') }}
              </b-form-checkbox>
            </b-col>
          </b-row>

          <b-row>
            <!-- save button -->
            <b-col
              cols="12"
              class="d-flex justify-content-end pt-1 pb-4 mb-2"
            >
              <b-button
                v-permission="'addMessageSettings'"
                type="submit"
                variant="gradient-primary"
                data-action-type="save"
              >
                <feather-icon
                  icon="CheckCircleIcon"
                  class="mr-25"
                />
                {{ $t("save") }}
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>

<script>
export default {
  data() {
    return {
      items: [],
      selectedItem: {
        id: 0,
        isActive: true
      },
      helper: {
        selectedProviderName: 'smsGateway',
        isOriginalActive: false
      }
    };
  },

  computed: {
    logoPath() {
      switch (this.helper.selectedProviderName) {
        case 'smsGateway':
          return require('../../../assets/images/SMSCompanies/SMSGetway.png')

        case 'smS4Jawaly':
          return require('../../../assets/images/SMSCompanies/4Jawaly.png');
          case 'smsMsegat':
          return require('../../../assets/images/SMSCompanies/mesgat.png');

        default:
         return ''
      }
    },

    providerWebiste() {
      switch (this.helper.selectedProviderName) {
        case 'smsGateway':
          return 'https://gateway.sa/'

        case 'smS4Jawaly':
          return 'https://www.4jawaly.com/';
          case 'smsMsegat':
          return 'https://web.msegat.com//';

        default:
         return ''
      }
    }
  },

  mounted() {
    this.getItems();
  },

  methods: {
    setProvider() {
      const isSignedBefore = this.items.find((x) => x.provider === this.helper.selectedProviderName);
      if (isSignedBefore) {
        this.selectedItem = isSignedBefore;
      } else {
        this.selectedItem = { id: 0, isActive: true };
        this.helper.isOriginalActive = false;
      }
    },
    getItems() {
      this.get({ url: 'MessagesSettings' }).then((data) => {
        this.items = data;
      })
      .then(() => {
        // set suitable provider name
        if (this.items.length === 0) {
          this.helper.selectedProviderName = 'smsGateway'
        } else {
          const isActiveExists = this.items.find((x) => x.isActive);
          this.helper.selectedProviderName = isActiveExists ? isActiveExists.provider : 'smsGateway';
        }
        // else if (this.items.length === 1) {
        //   this.helper.selectedProviderName = this.items[0].provider;
        // } else if (this.items.length === 2) {
        //   this.helper.selectedProviderName = this.items[1].provider;
        // }
        // set current state for provider then retreive it
        this.helper.isOriginalActive = this.items.find((x) => x.provider === this.helper.selectedProviderName).isActive;
        this.setProvider();
      });
    },

    beforeSave() {
      if (!this.selectedItem.senderName) {
        this.selectedItem.senderName = this.selectedItem.senderId;
      }
    },

    beforeInsert() {
      switch (this.helper.selectedProviderName) {
        case 'smsGateway':
          this.selectedItem.senderUrl = 'http://rest.gateway.sa/api/';
          this.selectedItem.provider = 'smsGateway';
          break;

        case 'smS4Jawaly':
          this.selectedItem.senderUrl = 'https://api-sms.4jawaly.com/api/v1/account/area/sms/send';
          this.selectedItem.provider = 'smS4Jawaly';
          break;
          case 'smsMsegat':
          this.selectedItem.senderUrl = 'https://www.msegat.com';
          this.selectedItem.provider = 'smsMsegat';
          break;

        default:
          this.selectedItem.senderUrl = ''
          this.selectedItem.provider = ''
          break;
      }
    },

    save() {
      // check if sender name not provided
      this.beforeSave();

      if (this.selectedItem.id > 0) {
        // update provider
        this.update({
          url: 'MessagesSettings',
          data: this.selectedItem,
          id: this.selectedItem.id,
        }).then(() => {
          this.doneAlert({ text: this.$t('updatedSuccessfully') });
          this.getItems();
        });

      // otherwise insert provider
      } else {
        this.beforeInsert();
        this.create({
          url: 'MessagesSettings',
          data: this.selectedItem
        }).then(() => {
          this.doneAlert({ text: this.$t('savedSuccessfully') });
          this.getItems();
        });
      }
    },
    smsGatewaySelected() {
      this.helper.selectedProviderName = 'smsGateway'
      this.setProvider();
    },
    smS4JawalySelected() {
      this.helper.selectedProviderName = 'smS4Jawaly'
      this.setProvider();
    },
    smsMesgatSelected() {
      this.helper.selectedProviderName = 'smsMsegat'
      this.setProvider();
    }
  },
};
</script>

<style>
label {
  font-size: 14px;
}
.logo-img {
  display: block;
  margin: 10px auto 20px;
  width: 280px;
  height: 100px;
}

.message-settings .gb-package {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 3px;
}
.message-settings label {
  display: block;
  font-size: 18px;
}
.message-settings span {
  display: block;
  margin-bottom: 12px;
  color: #a1a7ae;
  letter-spacing: 1px;
  font-size: 14px;
}
.message-settings {
  border-radius: 25px;
}
.message-settings button {
  font-size: 18px;
  letter-spacing: 1.2px;
}
.message-settings .gb-btn-child {
  border: 1px solid gainsboro;
}
.message-settings .btnActive {
  background-color: rgba(115, 103, 240, 0.12)
}
</style>
